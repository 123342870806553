<template>
    <div>
        <div class="intro-y box p-5 mt-4">
            <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="relative col-span-4">
                    <label for="form-title" class="form-label">
                        Название
                        <sup v-if="v$.form.title.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.title.$errors.length" for="form-title" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.title.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-title"
                        v-model="form.title"
                        type="text"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.form.title.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-4">
                    <label for="form-description" class="form-label">Описание</label>
                    <input
                        id="form-description"
                        v-model="form.description"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="relative col-span-4">
                    <label for="form-color" class="form-label">
                        Цвет
                        <sup v-if="v$.form.color.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.color.$errors.length" for="form-color" class="form-image-text-error">
                        {{ getOverrideMessage(v$.form.color.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-color"
                        v-model="form.color"
                        type="color"
                        class="form-control color-field w-full p-1"
                        :class="{ 'form-input-error': v$.form.color.$errors.length }"
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link :to="{ name: 'offers' }" class="btn btn-secondary w-24 mr-2 mb-2">Отмена</router-link>
                    <button type="button" class="btn btn-primary mr-2 mb-2" @click="() => save(true)">
                        Сохранить и продолжить
                    </button>
                    <button type="button" class="btn btn-primary mb-2" @click="save(false)">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Preloader from '@/components/preloader/Main';
import { usersMixin } from '@/mixins/form/users-mixin';
import { errorResponse } from '@/mixins/form/form-mixin';
import { helper as $h } from '@/utils/helper';

export default {
    name: 'Create',
    components: { Preloader },
    mixins: [usersMixin, errorResponse],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            loading: false,
            form: {
                title: null,
                color: null,
                description: null,
            },
        };
    },
    created() {
        this.$store.commit('main/setPageHeader', 'Спецпредложения / Создать');
    },
    validations() {
        return {
            form: {
                title: { required },
                color: { required },
            },
        };
    },
    methods: {
        async save(flag) {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;

            this.axios
                .post('/offers', { ...this.form })
                .then((rea) => {
                    this.loading = false;

                    if (flag) {
                        this.$router.push({ name: 'offers', params: { id: rea.data.data.id } });
                        this.$notify('Запись добавлена');
                    } else {
                        this.$router.push({ name: 'offers' });
                    }
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
    },
};
</script>

<style></style>
